
// import { Icon } from "@iconify/vue2";
import Vue from "vue";
export default Vue.extend({
  name: "AppCelebrationDialog",

  props: {
    emoji: String,
    message: String,
    show: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      visible: false,
    };
  },

  watch: {
    show(visible) {
      this.visible = visible;
    },
  },
});
