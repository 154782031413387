
import { Icon } from "@iconify/vue2";
export default {
  name: "AppBar",

  props: {
    showApp: Boolean,
    title: String,
    icon: String,
  },

  components: {
    Icon,
  },
};
