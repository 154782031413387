
import Vue from "vue";
import { EventBus } from "./main";
import { VuePwaInstallMixin, BeforeInstallPromptEvent } from "vue-pwa-install";
import AppBar from "./components/AppBar.vue";
import BottomNavigation from "./components/BottomNavigation.vue";
import AppCelebrationDialog from "./components/AppCelebrationDialog.vue";
import AppQRCodeDialog from "./components/AppQRCodeDialog.vue";
import { Icon } from "@iconify/vue2";
import confetti from "canvas-confetti";

export default Vue.extend({
  name: "App",

  mixins: [VuePwaInstallMixin],

  components: {
    AppBar,
    BottomNavigation,
    AppCelebrationDialog,
    AppQRCodeDialog,
    Icon,
  },

  computed: {
    appCssVars(): object {
      return {
        "--app-padding-bottom": this.appPaddingBottom,
      };
    },
  },

  data: () => ({
    showApp: false,
    windowWidth: window.innerWidth,
    appPaddingBottom: "56px",
    installPrompt: null as BeforeInstallPromptEvent | null,
    showInstallPrompt: false,
    showedInstallPrompt: false,
    menu: false,
    notifications: false,
    showCelebrationDialog: false,
    showQRCodeDialog: false,
    pageTitle: "",
    pageIcon: "",
    snackbar: {
      color: "blue",
      text: "",
      visible: false,
    },
    appMenuItems: [
      {
        name: "My Profile",
        icon: "fluent:person-circle-20-regular",
        methodName: "myProfile",
      },
      {
        name: "My Rewards",
        icon: "fluent:trophy-20-regular",
        methodName: "myRewards",
      },
      {
        name: "My Courses",
        icon: "fluent:hat-graduation-20-regular",
        methodName: "myCourses",
      },
      {
        name: "My Classes",
        icon: "fluent:screen-person-20-regular",
        methodName: "myClasses",
      },
      {
        name: "My QR Code",
        icon: "fluent:qr-code-20-regular",
        methodName: "showMyQRCode",
      },
      {
        name: "Scan QR Code",
        icon: "fluent:barcode-scanner-20-regular",
        methodName: "scanQRCode",
      },
      {
        name: "Settings",
        icon: "fluent:settings-20-regular",
        methodName: "settings",
      },
      {
        name: "Log Out",
        icon: "fluent:arrow-exit-20-regular",
        methodName: "logOut",
      },
    ],
  }),

  methods: {
    promptInstall() {
      this.showedInstallPrompt = true;
      this.showInstallPrompt = false;
      this.installPrompt?.prompt();
      this.installPrompt?.userChoice.then((choiceResult) => {
        this.installPrompt = null;
      });
    },
    handleResize() {
      if (
        this.windowWidth <= 768 &&
        screen.availHeight - window.innerHeight > 112
      ) {
        // mobile browser
        this.appPaddingBottom = "112px";
      } else {
        this.appPaddingBottom = "56px";
      }
    },
    hideApp(hide: boolean) {
      if (hide === true) this.showApp = false;
    },
    updatePageTitle(pageTitle: string) {
      this.pageTitle = pageTitle;
    },
    updatePageIcon(pageIcon: string) {
      this.pageIcon = pageIcon;
    },
    appMenuItemClick(methodName: string) {
      //TODO: call method by string name
      switch (methodName) {
        case "myProfile":
          return this.$router.push("/profile");
        case "myCourses":
          return this.$router.push("/student");
        case "showMyQRCode":
          this.menu = false;
          return this.showMyQRCode();
        case "scanQRCode":
          this.menu = false;
          return this.scanQRCode();
        case "settings":
          return this.settings();
        case "logOut":
          return this.logOut();
        default:
          break;
      }
    },
    // TODO
    // celebration(message: string) {
    celebration() {
      this.showCelebrationDialog = true;
      confetti({
        particleCount: 250,
      });
    },
    showMyQRCode() {
      this.showQRCodeDialog = true;
    },
    scanQRCode() {
      console.log("Scan QR Code");
    },
    settings() {
      this.$router.push("/settings");
    },
    logOut() {
      this.$router.push("/login");
    },
  },

  watch: {
    $route: function (route) {
      this.showApp =
        (route.meta && route.meta.protected && route.meta.protected === true) ||
        false;
    },
  },

  created() {
    EventBus.$on("confetti", (emoji: string, message: string) => {
      // TODO
      // this.celebration(emoji + " " + message);
      this.celebration();
    });
    EventBus.$on("error", (message: string) => {
      return (this.snackbar = {
        color: "red",
        text: message,
        visible: true,
      });
    });
    EventBus.$on("info", (message: string) => {
      return (this.snackbar = {
        color: "blue",
        text: message,
        visible: true,
      });
    });
    this.$on("canInstall", (event: BeforeInstallPromptEvent) => {
      if (!this.showedInstallPrompt && this.showApp) {
        event.preventDefault();
        this.installPrompt = event;
        this.showInstallPrompt = true;
      }
    });
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
});
