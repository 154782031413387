import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/SignUpView.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/ResetPasswordView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("../views/WelcomeView.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { protected: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
    meta: { protected: true },
  },
  {
    path: "/student",
    name: "student",
    component: () => import("../views/StudentView.vue"),
    meta: { protected: true },
  },
  {
    path: "/student-course",
    name: "student-course",
    component: () => import("../views/StudentCourseView.vue"),
    meta: { protected: true },
  },
  {
    path: "/teacher",
    name: "teacher",
    component: () => import("../views/TeacherView.vue"),
    meta: { protected: true },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("../views/ScheduleView.vue"),
    meta: { protected: true },
  },
  {
    path: "/to-do",
    name: "to-do",
    component: () => import("../views/ToDoView.vue"),
    meta: { protected: true },
  },
  {
    path: "/steps",
    name: "steps",
    component: () => import("../views/StepsView.vue"),
    meta: { protected: true },
  },
  {
    path: "/social",
    name: "social",
    component: () => import("../views/SocialView.vue"),
    meta: { protected: true },
  },
  {
    path: "/community",
    name: "community",
    component: () => import("../views/CommunityView.vue"),
    meta: { protected: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
